<template>
    <div
        id="layout"
        class="layout"
    >
        <SkipLinks />
        <TheMenuOverlay />

        <div class="layout__canvas">
            <transition>
                <TheNavBar
                    v-if="navVisible"
                    :variant="navBarVariant"
                />
            </transition>

            <main
                id="main-content-landmark"
                class="layout__main"
            >
                <slot />
            </main>

            <TheFooter
                id="footer-landmark"
                class="layout__footer"
            />
        </div>

        <BaseModal
            :is-visible="modalStore.activeModals['reviews-modal'] === true"
            :remove-from-dom-when-invisible="false"
            class="reviews-modal base-modal--reviews"
        >
            <template #title>{{ $t('reviews.reviews') }}</template>

            <template #default>
                <div id="reviews-modal" />
            </template>
        </BaseModal>
    </div>
</template>

<script setup>
import { useModalStore } from '~/store/modal';
const isMainMenuOpen = useIsMainMenuOpen();
const route = useRoute();

const modalStore = useModalStore();

const navBarVariant = computed(() => {
    if (route.name.startsWith('index')) {
        return 'home';
    } else if (route.name.startsWith('properties')) {
        return 'propertyPage';
    } else if (route.name.startsWith('restaurants')) {
        return 'restaurantPage';
    } else if (route.name.startsWith('stories-slug')) {
        return 'contentPage';
    } else if (route.name.startsWith('stories')) {
        return 'default';
    }
    return 'contentPage';
});

const navVisible = ref(true);

watch(() => route.name, () => {
    navVisible.value = false;

    setTimeout(() => {
        navVisible.value = true;
    }, 1000);
});

const i18nHead = useLocaleHead({
    addDirAttribute: true,
    addSeoAttributes: true
});

useHead({
    htmlAttrs: {
        ...i18nHead.value.htmlAttrs
    },
    bodyAttrs: {
        'menu-open': isMainMenuOpen
    },
    meta: [
        {
            name: 'theme-color',
            content: '#293B24'
        }
    ],
    link: [
        {
            rel: 'icon',
            href: '/favicon.ico',
            sizes: 'any'
        },
        {
            rel: 'icon',
            href: '/favicon.svg',
            type: 'image/svg+xml'
        },
        {
            rel: 'apple-touch-icon',
            href: '/apple-touch-icon-180x180.png'
        }
    ]
});
</script>

<style lang="less" src="./layout.less"></style>
