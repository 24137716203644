<template>
    <DnModal
        class="base-modal"
        @close="modalStore.deactivateAllModals"
        teleport-target="#teleports"
    >
        <template #close-button>
            <div class="modal__close">
                <BaseButton
                    class="base-button--icon-only"
                    icon="close"
                    aria-label="close"
                    @click="modalStore.deactivateAllModals"
                >
                    <BaseIcon icon="cross" />
                </BaseButton>
            </div>
        </template>

        <template #default>
            <div class="modal__header">
                <h2 class="modal__title"><slot name="title" /></h2>
            </div>

            <div class="modal__body">
                <slot name="default" />
            </div>
        </template>

        <template 
            v-if="!!$slots.dialog"
            #dialog
        >
            <slot name="dialog" />
        </template>
    </DnModal>
</template>

<script setup>
import DnModal from '@digitalnatives/modal';
import { useModalStore } from '~/store/modal';

const modalStore = useModalStore();
</script>

<style lang="less">
.base-modal {
    backdrop-filter: none;

    .dn-modal__backdrop {
        background: rgba(41, 59, 36, .215);
    }

    .dn-modal__wrapper {
        padding: 0;
    }

    .modal__title {
        .typography-h3();
        max-width: calc(100% - 3rem);
    }

    .dn-modal__dialog {
        position: absolute;
        max-height: calc(100vh - 3rem);
        bottom: 0;
        max-width: 37.75rem;
        padding: 1.5rem 1.5rem 4rem 1.5rem;
        background: var(--color-cream);
        border-radius: 0;
        box-shadow: none;

        @media @q-md-min {
            max-height: calc(100vh - 9rem);
            position: relative;
            padding: 3rem;
        }
    }

    .dn-modal__main {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        padding: 0;

        @media @q-md-min {
            gap: 2rem;
        }

        &, & p {
            font-size: var(--font-size-md);

            @media @q-md-min {
                font-size: var(--font-size-lg);
            }
        }
    }

    .modal__close {
        position: sticky;
        z-index: 10;
        top: 0;
        left: 100%;
        max-width: 2rem;
    }

    .base-button--icon-only {
        width: 2rem;
        height: 2rem;
        padding: 0;

        .dn-icon {
            margin: 0 auto;
        }
    }
}

.base-modal--reviews {
    .dn-modal__dialog {
        position: relative;

        @media @q-md-min {
            width: 43.625rem;
            max-width: unset;
        }
    }
}
</style>
