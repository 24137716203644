<template>
    <a
        ref="target"
        class="nav-bar__target-area"
    />
    <header
        v-bind="$attrs"
        class="nav-bar"
        :class="`nav-bar--${variant}`"
        :scroll-threshold-passed="!targetIsVisible"
    >
        <div
            class="nav-bar__properties"
            :should-appear-on-threshold="variant === 'home'"
        >
            <slot name="properties">

                <TheHamburgerMenu />

                <ThePropertySwitcher
                    class="nav-bar__threshold-target"
                />
            </slot>
        </div>

        <div class="nav-bar__actions">
            <slot name="menu">
                <TheLanguageSwitcher
                    v-if="width >= 992"
                    :style="bookButtonWidth ? `margin-right: ${bookButtonWidth}px` : null"
                    location="top"
                />
                <div
                    v-if="variant !== 'restaurantPage'"
                    :should-appear-on-threshold="variant === 'home' || variant === 'propertyPage'"
                >
                    <CallToActionButton
                        v-if="width >= 992"
                        class="nav-bar__action-button"
                        parent-component="TheNavBar"
                        :hide-icon-on-mobile="true"
                    />
                </div>
            </slot>
        </div>

        <nav class="nav-bar__inner">
            <span
                v-if="variant !== 'home' || navigationStore.isMainMenuOpen"
                class="nav-bar__logo"
                :data-animated="variant === 'home'"
            >
                <NuxtLinkLocale
                    to="/"
                    @click="navigationStore.closeMainMenu()"
                >
                    <TheLogo />
                </NuxtLinkLocale>
            </span>
        </nav>

        <!-- <div
            class="nav-bar__button"
            :should-appear-on-threshold="variant === 'home' || variant === 'propertyPage'"
        >
            <slot name="actions">
                <CallToActionButton
                    parent-component="TheNavBar"
                    :hide-icon-on-mobile="true"
                />
            </slot>
        </div> -->

        <TheBookingOverlay />
    </header>
</template>

<script setup>
import { ref } from 'vue';
import { useIntersectionObserver } from '@vueuse/core';
import { useNavigationStore } from '~/store/navigation';
import { useWindowSize } from '@vueuse/core';

const { width } = useWindowSize();

const navigationStore = useNavigationStore();

const props = defineProps({
    heroBackground: {
        type: String,
        required: false,
        default: 'var(--color-oasis-green)'
    },
    variant: {
        type: String,
        default: 'default',
        required: false,
        validator(value) {
            return [
                'default',
                'home',
                'propertyPage',
                'restaurantPage',
                'contentPage'
            ].includes(value);
        }
    },
    useScrollThreshold: {
        type: Boolean,
        default: true,
        required: false
    }
});

// Intersection observer
const target = ref(null);
const targetIsVisible = ref(true);

if (props.useScrollThreshold) {
    const { isSupported } = useIntersectionObserver(
        target,
        ([{ isIntersecting }]) => {
            if (!isSupported.value) {
                targetIsVisible.value = false;
                return;
            }

            targetIsVisible.value = isIntersecting;
        },
    );
}
import { useDropdownStore } from '@/store/dropdown';
const { dropdownOpen } = useDropdownStore();
</script>

<style lang="less">
@import './TheNavBar.less';
</style>
